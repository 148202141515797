<template>
  <div class="px-24 pb-[12rem] w-[100.8rem] mx-auto">
    <div class="flex gap-16 items-center h-[11.4rem] py-16">
      <h1 class="flex items-center gap-12 shrink-0">
        <img
          src="@/assets/images/common/gnb_stove_logo.svg"
          alt="STOVE logo"
          class="h-32 block cursor-pointer"
          @click="goStoveHome"
        />
        <img
          src="@/assets/images/common/gnb_studio_logo.svg"
          alt="studio logo"
          class="h-32 block cursor-pointer"
          @click="goHome"
        />
      </h1>
      <dropdown
        v-model="selectedVersion"
        :options="versions"
        containerClass="ml-auto"
        :dropdownProps="{
          size: 'lg',
          variant: 'line',
          distance: 4,
          offset: [0, 0],
          placement: 'bottom-end'
        }"
        closeOnClick
        @update:modelValue="onChangeVersion"
      />
    </div>
    <s-tabs
      v-model="selectedTab"
      size="sm"
      variant="line-fixed"
      class="stove-studio-tab-line"
      @update:modelValue="onChangeTab"
    >
      <s-tab-list>
        <s-tab-item v-for="item in terms" :key="item.subCategory" :value="item.subCategory">
          <span class="px-12 py-[.9rem] text-xl leading-xl text-center">
            {{ item.title }}
          </span>
        </s-tab-item>
      </s-tab-list>
      <s-tab-panels
        class="h-[calc(100vh-29.4rem)] studio-scrollbar-4 bg-surface-layer border-1 border-solid border-border"
      >
        <s-tab-panel v-for="item in terms" :key="item.subCategory" :value="item.subCategory">
          <safe-html class="fr-view py-[4.2rem] px-[5rem]" :html="item.text" tag="div" />
        </s-tab-panel>
      </s-tab-panels>
    </s-tabs>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import {
  fetchServiceTermsAndConditionHistoryApi,
  fetchSubscriptionTermsAndConditionApi,
  fetchTermContentApi
} from '@/apis/terms.api';
import SafeHtml from '@/components/common/safe-html.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import { PLATFORM, SERVICE_ID, SERVICE_TYPES, VIEW_AREA } from '@/constants/common.const';
import { YEAR_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { BRAND_PAGE_URL, HOME_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useTermsStore } from '@/stores/terms.store';
import { useUserStore } from '@/stores/user.store';
import type { FormOption } from '@/types/common/form.type';
import type { TermTabModel } from '@/types/terms/terms-model.type';
import type { DetailContentResponse, TermHistory } from '@/types/terms/terms-response.type';
import { getConfigs, redirectTo } from '@/utils/common.util';
import { formatDateTime } from '@/utils/date.util';

definePageMeta({
  layout: false
});

const route = useRoute();
const router = useRouter();

const subCategory = route.query.subCategory as string;
const contentsNo = Number(route.query.contentsNo);

const { locale } = useI18n();
const termsStore = useTermsStore();
const { firstSignUpNation } = storeToRefs(termsStore);

const selectedVersion = ref<number>(contentsNo);
const versions = ref<FormOption<number>[]>([]);

const selectedTab = ref<string>(subCategory);
const terms = ref<TermTabModel[]>([]);

const userStore = useUserStore();

const { selectedGroupId, joinedGroups } = storeToRefs(userStore);

const getTermContent = async (contentsNo: number) => {
  const result: DetailContentResponse | undefined = await fetchTermContentApi(contentsNo);
  if (!result) {
    return;
  }
  const updatedIndex = terms.value.findIndex((term: any) => term.subCategory === selectedTab.value);
  replaceTermAtIndex(updatedIndex, {
    subCategory: selectedTab.value,
    title: result.title,
    text: result.text,
    contentsNo
  });
};

const goHome = async () => {
  let groupId = selectedGroupId.value;
  if (!groupId) {
    groupId = await userStore.findReadableGroupHome();
  }
  if (joinedGroups.value?.length === 0 || !groupId) {
    return await redirectTo(BRAND_PAGE_URL);
  }
  await redirectTo(`/${locale.value}${HOME_PAGE_URL}`, { groupId, external: true });
};

const goStoveHome = async () => {
  const { STORE_URL } = getConfigs();
  await redirectTo(`${STORE_URL}/${locale.value}`, { external: true, open: { target: '_blank' } });
};

const replaceTermAtIndex = (index: number, term: TermTabModel) => {
  terms.value.splice(index, 1, term);
};
const onChangeVersion = async (value: number | string) => {
  selectedVersion.value = Number(value);
  await getTermContent(selectedVersion.value);
};

const getSubscriptionTerms = async () => {
  const result = await fetchSubscriptionTermsAndConditionApi({
    serviceId: SERVICE_ID.INDIEGAME,
    viewareaId: VIEW_AREA.STDJOIN,
    nation: firstSignUpNation.value,
    lang: locale.value,
    textYn: Confirmation.YES,
    textFormat: PLATFORM.PC
  });
  if (!result || !result.serviceInfos || !result.serviceInfos[0].contentsList) {
    return;
  }
  terms.value =
    result.serviceInfos[0].contentsList.map((item: any) => ({
      subCategory: item.subCategory,
      title: item.title,
      text: item.text,
      contentsNo
    })) || [];

  terms.value.sort((a: TermTabModel) => (a.subCategory === 'STUDIOJOIN' ? -1 : 1));

  selectedTab.value = subCategory || terms.value[0].subCategory;
};

const getTermHistory = async (subCategory: string) => {
  const result = await fetchServiceTermsAndConditionHistoryApi({
    serviceType: SERVICE_TYPES.GAME_ID,
    serviceId: SERVICE_ID.INDIEGAME,
    nation: firstSignUpNation.value,
    platform: PLATFORM.PC,
    lang: locale.value,
    subCategory
  });
  if (!result || !result.contentsList) {
    return;
  }
  const latestContentsList = result.contentsList
    .sort((a: TermHistory, b: TermHistory) => b.enforcedDt - a.enforcedDt)
    .slice(0, 5);

  versions.value = latestContentsList.map((item: TermHistory) => ({
    value: item.contentsNo,
    label: `${formatDateTime(item.enforcedDt, YEAR_FORMAT_WITH_DOT)} (v${item.versionIdx})`
  }));

  selectedVersion.value = versions.value[0].value || contentsNo;
  router.replace({
    query: {
      subCategory,
      contentsNo: versions.value[0].value
    }
  });
};

const onChangeTab = async () => {
  await getTermHistory(selectedTab.value);
};

const init = async () => {
  await getSubscriptionTerms();
  await getTermHistory(selectedTab.value || terms.value[0].subCategory);
};

init();
</script>
